<template>
    <div class="mainlist">
        <el-form class="mainContent" label-width="0">
            <el-form-item style="margin-bottom:0;">
                <div class="question-title" style="margin-top: 10px;">就诊记录</div>
            </el-form-item>
            <el-form-item style="margin-bottom:0;">
                <div class="firsttitle">就诊信息</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">就诊日期</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.visitDate}}</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">就诊医院</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.hospitalName}}</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">诊断意见</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.commit}}</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">治疗方案</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.treatment}}</el-col>
                </el-row>
            </el-form-item>
            <el-form-item style="margin-bottom:0;">
                <div class="firsttitle">实验室检查结果</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">促卵泡生产激素(FSH)</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.verificationResult1}}mIU/mL</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">促黄体生产激素(LH)</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.verificationResult2}}mIU/mL</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">雌二醇(E2)</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.verificationResult3}}pmol/mL</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">孕酮(P)</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.verificationResult4}}nmol/L</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">催乳激素(PRL)</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.verificationResult5}}mIU/L</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">睾酮(T)</el-col>
                    <el-col :span="12">{{gnrhMedicalForm.verificationResult6}}nmol/L</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">常染色体检查</el-col>
                    <el-col :span="12" v-if="gnrhMedicalForm.verificationResult7 === 1">正常</el-col>
                    <el-col :span="12" v-if="gnrhMedicalForm.verificationResult7 === 0">异常</el-col>
                </el-row>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "GnrhMedicalDetail",
        data() {
            return {
                gnrhMedicalForm: {
                },
                token: '',
                uuid: ''
            }
        },
        mounted() {
            this.token = this.$route.query.token;
            this.uuid = this.$route.query.uuid;
            this.getGnrhMedicalRecord();
        },
        methods: {
            getGnrhMedicalRecord() {
                let that = this;
                that.$axios({
                    method: "get",
                    url: "/api/api/patientGnrhMedicalRecord/detail/" + that.uuid
                }).then(res => {
                    if(res.data.code === 200) {
                        that.gnrhMedicalForm = res.data.data;
                    } else {
                        that.$message({
                            message: res.data.message,
                            type: "error"
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .mainlist {
        padding: 0;
    }
    .mainContent {
        overflow-x: hidden;
        background-color: #fff;
        border-radius: 10px;
        padding: 0 10px 1.25rem 10px;
    }
    .question-title {
        height: 3.125rem;
        line-height: 3.125rem;
        margin: 0;
        text-align: center;
        font-size: 1.375rem;
    }
    .firsttitle {
        font-size: 1rem;
        padding: 0 10px;
        height: 2.875rem;
        line-height: 2.875rem;
        border-bottom: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        background-color: #f8f9fc;
    }
    .questionmain {
        font-size: 0.875rem;
        margin: 0.625rem;
        border-bottom: 1px dashed #ddd;
    }
</style>
